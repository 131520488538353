// TODO: Begriffe!!!!!
// Filter mit ..A gibt es nicht, das sind Facetten
export const FILTER_NON_SMOKING = "nsm";
export const FILTER_VEHICLE_TYPE = "ty";
export const FILTER_VEHICLE_TYPE_ALL = "tyA";
export const FILTER_VEHICLE_CONDITION = "st";
export const FILTER_VEHICLE_CONDITION_ALL = "stA";
export const FILTER_VEHICLE_BODYTYPE = "bo";
export const FILTER_VEHICLE_BODYTYPE_ALL = "boA";
export const FILTER_VEHICLE_MANUFACTURER = "ma";
export const FILTER_VEHICLE_MODEL = "mo";
export const FILTER_VEHICLE_FUEL = "mt";
export const FILTER_VEHICLE_FUEL_ALL = "mtA";
export const FILTER_VEHICLE_GEARBOX = "tr";
export const FILTER_VEHICLE_GEARBOX_ALL = "trA";
export const FILTER_VEHICLE_EQUIPMENT = "ai";
export const FILTER_VEHICLE_EQUIPMENT_MOST_WANTED = "aim";
export const FILTER_VEHICLE_EXTERIR_COLOR = "co";
export const FILTER_VEHICLE_EXTERIR_COLOR_ALL = "coA";
export const FILTER_VEHICLE_INTERIR_COLOR = "ci";
export const FILTER_VEHICLE_INTERIR_COLOR_ALL = "ciA";
export const FILTER_VEHICLE_INTERIR_MATERIAL = "spo";
export const FILTER_VEHICLE_INTERIR_MATERIAL_ALL = "spoA";
export const FILTER_VEHICLE_NUMBER_OF_DOORS = "doK";
export const FILTER_VEHICLE_NUMBER_OF_DOORS_ALL = "doKA";
export const FILTER_VEHICLE_FUEL_EFFICIENCY_RATING = "ef";
export const FILTER_VEHICLE_EMISSION_STICKER = "es";
export const FILTER_VEHICLE_EMISSION_CATEGORY = "ec";
export const FILTER_VEHICLE_QUALITY_SEAL = "qs";
export const FILTER_VEHICLE_BRANCH = "lo";
export const FILTER_VEHICLE_BRANCH_ALL = "loA"; //den gibt es nicht als Filter, das ist eine Facette!!!!!
export const FILTER_VEHICLE_METALLIC = "cm";
export const FILTER_VEHICLE_PAYMENT_METHOD = "vat";
export const FILTER_VEHICLE_WARRANTY = "wa";
export const FILTER_VEHICLE_AVAILABLE = "vf";
export const FILTER_VEHICLE_RANGE = "er";
export const FILTER_VEHICLE_PLUG_TYPE = "pt";
export const FILTER_VEHICLE_LOAD_TIME = "xx";

export const FILTER_BIKE_CATEGORY = "bk";
export const FILTER_BIKE_CATEGORY_ALL = "bkA";
export const FILTER_BIKE_WHEELSIZE = "ws";
export const FILTER_BIKE_WHEELSIZE_ALL = "wsA";
export const FILTER_BIKE_GEARTYPE = "gt";
export const FILTER_BIKE_GEARTYPE_ALL = "gtA";
export const FILTER_BIKE_BATTERY_MANUFACTURER = "bm";
export const FILTER_BIKE_BATTERY_MANUFACTURER_ALL = "bmA";
export const FILTER_BIKE_FRAME_MATERIAL = "fm";
export const FILTER_BIKE_FRAME_MATERIAL_ALL = "fmA";
export const FILTER_BIKE_FRAME_SHAPE = "fs";
export const FILTER_BIKE_FRAME_SHAPE_ALL = "fsA";
export const FILTER_BIKE_COLOR = "co";
export const FILTER_BIKE_COLLOR_ALL = "coA";
export const FILTER_BIKE_MOTOR_POSITION = "mp";
export const FILTER_BIKE_MOTOR_POSITION_ALL = "mpA";
export const FILTER_BIKE_FRAME_HEIGHT = "fh";
export const FILTER_BIKE_FRAME_HEIGHT_ALL = "fhA";
export const FILTER_BIKE_BATTERY_POSITION = "bp";
export const FILTER_BIKE_BATTERY_POSITION_ALL = "bpA";

export const TYPE_PKW = 1;
export const TYPE_LKW = 2;
export const TYPE_E_BIKE = 6;

export const TYPE_NEW_VEHICLE = 1;
export const TYPE_USED_VEHICLE = 2;

export const TYPE_WOHNMOBIL = 3;
export const TYPE_MOTORCYCLE = 4;

export const TYPE_PKW_BIN = 1; // 0x01
export const TYPE_WOHNMOBIL_BIN = 6; // 0x06 (0x02 + 0x04)
export const TYPE_MOTORRAD_BIN = 8; // 0x08
export const TYPE_LKW_BIN = 64; // 0x40
