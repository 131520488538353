// TODO: am besten hier noch das ganze Axios einbauen

export function getBackend(path: string) {
  return "https://mpi.carzilla.de" + path;
  //  return "https://search.carzilla.de" + path;
}

export function getBackendApi(path: string) {
  return getBackend("/proxy/v1") + path;
}

export function getBackendApi2(path: string) {
  return getBackend("/proxy/v2") + path;
}
