import { TranslationsCustomer } from "./translationsCustomer";

const deDE: TranslationsCustomer = {
  54440: {
    additionalInfo: {
      0: "Mind. 1 Jahr Garantie, gültig innerhalb des Herstellernetzwerkes in Europa",
      1: "14 Tage Geld zurück Garantie",
      2: "Keine Wartungskosten für min. 1 oder 10.000 km"
    }
  }
};

export default deDE;
