import { Translations } from "./translations";
//ToDo : Wos strig steht muss noch die Übersetzung hin
const bnBD: Translations = {
  language: "ভাষা",
  Vorlauf: "",
  Bestand: "",
  Verkauft: "",
  Speichern: "",
  Verkaufsdatum: "",
  Verfügbarkeitsdatum: "",
  AvailableDialogText: "",
  RetrieveDialogText: "",
  SellDialogText: "",
  UserProfile: "ব্যবহারকারীর প্রোফাইল",
  Filteroptionen: "ফিল্টার অপশন",
  FilterSchließen: "Filter schließen",
  CreateNewBike: "",
  ColumnDefinition: "",
  EditBranch: "",
  BranchId: "",
  BranchName: "",
  Street: "",
  PLZ: "",
  Location: "",
  AliasName: "",
  Phone: "",
  Fax: "",
  Basisdaten: "",
  Öffnungszeiten: "",
  Day: "",
  From: "",
  To: "",
  ManufacturerColor: "",
  DealerPriceGross: "",
  EditMultipleBikes: "",
  EditInternalNumber: "",
  EditModelDescription: "",
  EditBatteryCapacity: "",
  EditManufacturerColor: "",
  EditNumberOfGears: "",
  EditFrameNumber: "",
  EditFrameHeight: "",
  EditWeight: "",
  EditPriceGross: "",
  EditEan: "",
  AssignedColumns: "",
  AvailableColumns: "",
  TableName: "",
  ArrangeColumnsForward: "",
  ArrangeColumnsBackward: "",
  SetTableName: "",
  DiscardChanges: "",

  common: {
    and: "এবং",
    brutto: "মোট",
    netto: "নেটো",
    notAvailable: "পাওয়া যায়নি",
    von: "??",
    bis: "??"
  },
  Buttons: {
    Close: "বন্ধ করুন",
    Cancel: "",
    Store: "",
    End: "",
    Yes: "",
    No: "",
    DeleteForm: "",
    CreateBike: "anlegen",
    SaveBike: "speichern",
    BikeVerfügbar: "Bike ist verfügbar",
    BikeVerkaufen: "",
    VerkaufFehler: "",
    NichtVerfügbar: "",
    FilterZurücksetzen: "ফিল্টার রিসেট করুন",
    ExportCsv: "CSV হিসাবে রপ্তানি করুন৷",
    ViewEditBike: "",
    CopyBike: "",
    SellBike: "",
    SaveSorting: "",
    SetupTable: ""
  },
  tabs: {
    t0: "সংক্ষেপ",
    t1: "ডেটা",
    t2: "ছবি",
    t3: "রপ্তানি",
    t4: "অবস্থা"
  },
  filters: {
    manufacturer: "",
    category: "",
    wheelSize: "",
    color: "",
    suitableFor: " ",
    frameMaterial: "",
    frameShape: "",
    batteryPosition: "",
    batteryManufacturer: "",
    motorPosition: "",
    gearType: "",
    model: "",
    frameHeight: "",
    batteryCapacity: "",
    numberOfGears: "",
    weight: "",
    price: "",
    vehicleType: "",
    branch: ""
  },

  Bike: {
    ID: "আইডি",
    FilialId: "শাখা আইডি",
    Hersteller: "উত্পাদক",
    Kategorie: "ধরন",
    Preis: "দাম",
    passendfür: "যাদের জন্য",
    Farbe: "রঙ",
    Rahmenform: "ফ্রেম ধরণ",
    RahmenMaterial: "কাঠামোর উপাদান",
    BatterieHersteller: "ব্যাটারি প্রস্তুতকারক",
    BatteriePosition: "ব্যাটারির অবস্থান",
    Motorposition: "মোটর অবস্থান",
    Gangschaltung: "গিয়ার শিফট",
    Radgröße: "চাকার আকার",
    Batteriekapazität: "ব্যাটারির ক্ষমতা",
    Getriebeart: "গিয়ার কাউন্ট",
    Modellbeschreibung: "string",
    Rahmennummer: "string",
    Rahmenhöhe: "string",
    Gewicht: "string",
    AnzahlDerGänge: "string",
    GeeignetFür: "string",
    Zustand: "string",
    HerstellerFarbname: "string",
    LeasingVerfügbar: "string",
    NichtRepSchaden: "string",
    Mwstsatz: "ভ্যাট",
    MwstAusweisbar: "ভ্যাট রিপোর্টযোগ্য",
    Nettopreis: "মোট মূল্য",
    Bruttopreis: "অমার্জিত মূল্য",
    Bild: "ছবি",
    GTIN: "GTIN",
    Beschreibung: "বর্ণনা",

    FrameMaterial: {
      ALUMINIUM: "অ্যালুমিনিয়াম",
      CARBON: "কার্বন",
      STEEL: "ইস্পাত",
      TITANIUM: "টাইটানিয়াম"
    },
    internalNumber: "অভ্যন্তরীণ নম্বর",

    BatteryManufacturer: {
      BOSCH: "বসচ",
      SHIMANO: "শিমানো",
      PANASONIC: "প্যানাসোনিক",
      YAMAHA: "ইয়ামাহা",
      Mahle: "মাহলে",
      Fazua: "ফজুয়া",
      OTHER_MANUFACTURER: "অন্যান্য উত্পাদক"
    },
    Manufacturer: {
      ADVANCED: "উন্নত",
      BABBOES: "বাবোস",
      BAKFIETS: "বাকফিট",
      BATAVUS: "বাটাভাস",
      BENNOBIKES: "বেনো বাইকস",
      BERGAMONT: "বার্গামন্ট",
      BIRIA: "বিরিয়া",
      BMC_BIKES: "বিএমসি বাইকস",
      BMW: "বিএমডব্লিউ",
      BRENNABOR: "ব্রেন্নাবর",
      BULLIT: "বুলিট",
      BUTCHERS_AND_BICYCLES: "বুচের এবং বাইসাইকেল",
      CA_GO: "কা গো",
      CANNONDALE: "ক্যাননডেল",
      CANYON: "ক্যানিয়ন",
      CARLA_CARGO: "কারলা কার্গো",
      CARQON: "কারকন",
      CARVER: "কারভার",
      CENTURION: "সেন্টুরিয়ন",
      CHIKE: "চিকে",
      CONTOURA: "কন্টুরা",
      CONWAY: "কনওয়ে",
      CUBE: "কিউব",
      DIAMANT: "ডায়ামন্ট",
      DOLLY_BIKES: "ডলি বাইকস",
      DYNABIKE: "ডাইনাবাইক",
      ELECTRA: "ইলেক্ট্রা",
      EXCELSIOR: "এক্সেলসিয়র",
      FISCHER: "ফিসার",
      FLYER: "ফ্লায়ার",
      FOCUS: "ফোকাস",
      GAZELLE: "গাজেল",
      GHOST: "ভুত",
      GRACE: "গ্রেস",
      GUDEREIT: "গুদেরেইট",
      HAIBIKE: "হাইবাইক",
      HARTJE: "হার্টজ",
      HASE_BIKES: "হাসে বাইকস",
      HEAD_BIKE: "হেড বাইক",
      HERCULES: "হার্সুলিস",
      HINTERHER: "হিন্টারহের",
      HNF: "এইচএনএফ",
      HOHEACHT: "হোহি এক্ট",
      HUSQVARNA: "হাস্কভার্না",
      ISY: "ইসি",
      JOHANSSON: "জোহানসন",
      KALKHOF: "কালকফ",
      KAYZA: "কাইজা",
      KETTLER: "কেটলার",
      KOGA: "কোগা",
      KREIDLER: "ক্রাইডলার",
      KTM: "কেটিএম",
      LANDROVER_WL_BRAND: "ল্যান্ড রোভার (ডাবলিউএল ব্র্যান্ড)",
      LAPIERRE: "লাপিয়ের",
      LEKKER: "লেকার",
      LEMMO: "লেমো",
      LETTLER: "লেটার",
      LOVENS: "লোভেন্স",
      MERCEDES_BENZ: "মার্সিডিস-বেন্জ",
      MERIDA: "মেরিডা",
      MOUSTACHE: "মুস্টাচ",
      NIHOLA: "নিহোলা",
      OMNIUM: "অমনিয়াম",
      ORBEA: "অরবিয়া",
      PEGASUS: "পেগাসাস",
      PINARELLO: "পিনারেলো",
      PORSCHE: "পোর্শ",
      PROPAIN: "প্রোপেন",
      PROPHETE: "প্রফেট",
      QIO: "কিউ",
      RABENEICK: "রাবেনেইক",
      RADON: "রেডন",
      RALEIGH: "রালে",
      RAYMON: "রেমন",
      RIESE_UND_MUELLER: "রিস এবং মুলার",
      ROCKY_MOUNTAIN: "রকি মাউন্টেন",
      ROSE: "রোজ",
      ROTWILD: "রটভাইল্ড",
      SANTA_CRUZ: "সান্তা ক্রুজ",
      SCHOOF_UND_JENSEN: "শুফ এবং জেনসেন",
      SCOTT: "স্কট",
      SIMPLON: "সিম্পলন",
      SPECIALIZED: "বিশেষ",
      STEVENS: "স্টিভেন্স",
      STROMER: "স্ট্রোমার",
      TERN: "টার্ন",
      TREK: "ট্রেক",
      TRIOBIKE: "ত্রিওবাইক",
      TRIUMPH: "ত্রিয়াম্ফ",
      UNION: "ইউনিয়ন",
      UNIVEGA: "ইউনিভেগা",
      URBAN_ARROW: "আর্বান আরো",
      URWAHN: "উরওয়ান",
      VAN_RAAM: "ভ্যান রাম",
      VAUN: "ভাউন",
      VELOE: "ভেলো",
      VELORETTI: "ভেলোরেটি",
      VIKTORIA: "ভিক্টোরিয়া",
      WALDBIKE: "ওয়াল্ডবাইক",
      WILIER: "উইলিয়ার",
      WINORA: "উইনোরা",
      WINTHER_BIKES: "উইন্টার বাইকস",
      WOOM: "ওয়ুম",
      YOONIT: "ইউনিট",
      GIANT: "দৈত্য"
    },
    BatteryPosition: {
      INTEGRATED: "সংস্কৃত",
      FRAME: "ফ্রেম",
      RACK: "র্যাক"
    },

    MotorPosition: {
      MIDDLE_MOTOR: "মধ্যের মোটর",
      INTERNAL_GEAR: "অভ্যন্তরীণ গিয়ার"
    },
    Category: {
      CargoBike: "কার্গো বাইক",
      FoldingBike: "ফোল্ডিং বাইক",
      TrekkingBike: "ট্রেকিং বাইক",
      MountainBike: "পর্বত বাইক",
      CityBike: "শহর বাইক",
      CrossBike: "ক্রস বাইক",
      RacingBike: "রেসিং বাইক",
      FatBike: "ফ্যাট বাইক",
      ChildrenBike: "বাচ্চাদের বাইক",
      OtherBike: "অন্যান্য বাইক"
    },

    Color: {
      BLACK: "কালো",
      GREY: "ধূসর",
      BEIGE: "বেজ",
      BROWN: "বাদামি",
      RED: "লাল",
      GREEN: "সবুজ",
      BLUE: "নীল",
      PURPLE: "বেগুনি",
      GOLD: "সোনা",
      WHITE: "সাদা",
      ORANGE: "কমলা",
      SILVER: "রূপালী",
      YELLOW: "হলুদ"
    },

    Condition: {
      New: "নতুন"
    },

    FrameShape: {
      LOW_STEP: "নিচু ধাপ",
      TRAPEZE: "ট্রাপেজ",
      CROSSBAR: "ক্রসবার",
      FULLY: "পুরোপুরি",
      MountainBike: "পর্বত সাইকেল",
      OTHER_SHAPE: "অন্যান্য আকৃতি"
    },

    GearType: {
      DERAILLEUR_GEARS: "ডেরেলিয়ার গিয়ার",
      INTERNAL_GEARED_HUB: "অভ্যন্তরীণ গিরহ হাব",
      CONTINUOUSLY_VARIABLE: "অবিরত পরিবর্তনশীল",
      OTHER_GEAR_TYPE: "অন্যান্য গিয়ার ধরণ"
    },
    WheelSize: {
      Size12: '12"',
      Size16: '16"',
      Size20: '20"',
      Size24: '24"',
      Size26: '26"',
      Size275: '27.5"',
      Size28: '28"',
      Size29: '29"',
      Size2026: '20/26"',
      Size20275: '20/27.5"',
      Size29275: '29/27.5"'
    },

    SuitableFor: {
      FEMALE: "নারী",
      MALE: "পুরুষ",
      UNISEX: "মহিলা এবং পুরুষ",
      GIRLS: "মেয়েদের",
      BOYS: "ছেলেদের"
    },

    PriceType: {
      FIXED: "নির্দিষ্ট",
      ON_REQUEST: "অনুরোধ অনুযায়ী",
      LEASING_ONLY: "কেবল লিজিং"
    },

    Currency: {
      EUR: "€"
    }
  },

  bike_internal_number: "আন্তঃক্রিয় নম্বর সহ বাইক",
  showLess: "",
  showMore: ""
};

export default bnBD;
