export const formatGermanNumber = (number: number): string => {
  return number.toLocaleString("de-DE");
};

export const isEmpty = (obj: object): boolean => {
  return Object.entries(obj).length === 0;
};

export const createScript = (src: string): HTMLScriptElement => {
  const script = document.createElement("script");
  script.src = src;
  script.async = true;
  return script;
};

export const findVehicleIdInCars = (vehicleId: number, cars: any) => {
  for (let i = 0; i < cars.length; i++) {
    const objekt = cars[i];
    if (objekt.li && objekt.li.endsWith(vehicleId)) {
      return i;
    }
  }
  return -1;
};
