import React, { createContext, useState, ReactNode } from "react";
import useStore from "@store";

type MenuContextType = {
  isMenuOpen: boolean;
  toggleMenu: () => void;
  isSmallOpen: boolean;
  toggleSmall: () => void;
  setViewOpen: (isViewOpen: number) => void;
  isViewOpen: number;
};

const defaultMenuContext: MenuContextType = {
  isMenuOpen: true,
  toggleMenu: null,
  isSmallOpen: true,
  toggleSmall: null,
  setViewOpen: null,
  isViewOpen: 1
};

export const MenuContext = createContext<MenuContextType>(defaultMenuContext);

export const MenuProvider = ({ children }: { children: ReactNode }) => {
  const { customerConfiguration, setCustomerConfiguration } = useStore();

  // Standardwert auf true setzen, falls customerConfiguration undefiniert ist oder keine isSmallOpen-Eigenschaft hat
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSmallOpen, setIsSmallOpen] = useState(customerConfiguration?.isSmallOpen !== undefined ? customerConfiguration?.isSmallOpen : defaultMenuContext.isSmallOpen);
  const [isViewOpen, setViewOpen] = useState(1);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleSmall = () => {
    setIsSmallOpen(false);
  };

  return (
    <MenuContext.Provider
      value={{
        isMenuOpen,
        toggleMenu,
        isSmallOpen,
        toggleSmall,
        setViewOpen,
        isViewOpen
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};
