//polyfills for prod environment. Some newer features are not available in prod, so we need this file

if (!Promise.allSettled) {
  Promise.allSettled = function (promises: any) {
    return Promise.all(
      promises.map((p: any) =>
        Promise.resolve(p).then(
          (value) => ({
            status: "fulfilled",
            value
          }),
          (reason) => ({
            status: "rejected",
            reason
          })
        )
      )
    );
  };
}
