import { create } from "zustand";
import { persist } from "zustand/middleware";
import { ISearchParametersProps } from "@/interfaces/ISearchParametersProps";
import { ICustomerConfiguration } from "@/interfaces/ICustomerConfiguration";
import { IFacets } from "@/interfaces/IFacets";
import { IRanges, IRanges1 } from "@/interfaces/IRanges";
import { IEquipmentCategory } from "@/interfaces/IEquipment";

/*****STATES*****/
interface StoreState {
  vat: number;
  searchObject: ISearchParametersProps | null;
  customerConfiguration: ICustomerConfiguration | null;
  selectedCarLink: string | null;
  facets: IFacets;
  searchResult: any;
  rg: IRanges;
  searchObjectNames: { [key: string]: string } | null;
  bearerToken: string | null;
  equipments: IEquipmentCategory[];
  listSelectedSort: string;
  listSortOrder: boolean;
}
const initializeState = (): StoreState => ({
  vat: 19,
  searchObject: null,
  customerConfiguration: null,
  selectedCarLink: null,
  facets: null,
  searchResult: null,
  rg: null,
  searchObjectNames: null,
  bearerToken: null,
  equipments: null,
  listSelectedSort: "new",
  listSortOrder: true
});

/*****ACTIONS*****/
interface StoreActions {
  setVat: (value: number) => void;
  setSearchObject: (value: ISearchParametersProps) => void;
  setCustomerConfiguration: (value: ICustomerConfiguration) => void;
  setSelectedCarLink: (value: string) => void;
  setFacets: (value: IFacets) => void;
  setSearchResult: (value: any) => void;
  setRg: (value: IRanges1) => void;
  setSearchObjectNames: (value: { [key: string]: string }) => void;
  setBearerToken: (value: string) => void;
  setEquipments: (value: IEquipmentCategory[]) => void;
  setListSelectedSort: (value: string) => void;
  setListSortOrder: (value: boolean) => void;
}
const initializeActions = (set: any): StoreActions => ({
  setVat: (value) => set({ vat: value }),
  setSearchObject: (value) => set({ searchObject: value }),
  setCustomerConfiguration: (value) => set({ customerConfiguration: value }),
  setSelectedCarLink: (value) => set({ selectedCarLink: value }),
  setFacets: (value) => set({ facets: value }),
  setSearchResult: (value) => set({ searchResult: value }),
  setRg: (value) => set({ rg: value }),
  setSearchObjectNames: (value) => set({ searchObjectNames: value }),
  setBearerToken: (value) => set({ bearerToken: value }),
  setEquipments: (value) => set({ equipments: value }),
  setListSelectedSort: (value) => set({ listSelectedSort: value }),
  setListSortOrder: (value) => set({ listSortOrder: value })
});

const useStore = create<StoreState & StoreActions>()(
  persist(
    (set) => ({
      ...initializeState(),
      ...initializeActions(set)
    }),
    {
      name: "dotzilla-mpi-storage"
    }
  )
);

export default useStore;
