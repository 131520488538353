import React, { useEffect, useState, Suspense } from "react";
import { useLocation, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import "react-toastify/dist/ReactToastify.css";
import { getBackendApi2 } from "./tools/backendApis";
import { GetFacettenFromApi } from "./api/GetFacettenFromApi";
import { DataArrayContext } from "./contexts/dataArrayContext";
import { ISelectData } from "@/interfaces/ISelectData";
import { ICustomerConfiguration } from "@/interfaces/ICustomerConfiguration";
import { IData } from "@/interfaces/IData";
import { ISearchParametersProps } from "@/interfaces/ISearchParametersProps";
import useStore from "@store";
import { useNavigate } from "react-router-dom";
import { createQueryParams, parseQueryParams } from "@/tools/tools";

//views
const QuickSearch = React.lazy(() => import("@/views/QuickSearch/QuickSearch"));
const VehicleList = React.lazy(() => import("@/views/VehiclesList/VehicleList"));
const VehicleDetail = React.lazy(() => import("@/views/VehicleDetail/VehicleDetail"));
const DetailSearch = React.lazy(() => import("@/views/DetailSearch/DetailSearch"));
const VehicleSlider = React.lazy(() => import("@/views/VehicleSlider/VehicleSlider"));
const Configurator = React.lazy(() => import("@/views/Admin/Configurator/Configurator"));
const Login = React.lazy(() => import("@/views/Admin/Login/Login"));
const NotFoundPage = React.lazy(() => import("@/views/NotFound404/NotFoundPage"));

interface MainContainerProps {
  customerConfig: ICustomerConfiguration;
}

const MainContainer: React.FC<MainContainerProps> = ({ customerConfig }) => {
  const { setVat, listSelectedSort, listSortOrder } = useStore();
  const location = useLocation();
  const navigate = useNavigate();
  const [width] = useState(customerConfig.width);
  const defaultSearch: ISelectData = {
    sort: listSelectedSort,
    sortd: listSortOrder ? "ASC" : "DESC"
  };
  const queryParams = parseQueryParams(location.search); //read filter parameter from URL
  const [searchObject, setSearchObject] = useState<ISelectData>(queryParams || defaultSearch);

  const url = getBackendApi2("/");
  const pathName = location.pathname;
  const onlyFacetten = pathName === customerConfig.quickSearch || pathName === customerConfig.detailSearch;
  const data: IData = GetFacettenFromApi(searchObject, url, onlyFacetten);
  if (!data) {
    return;
  }

  //set current vat value
  useEffect(() => {
    if (data && data.searchResult && Object.keys(data).length) {
      setVat(data.searchResult.cV);
    }
  }, [data]);

  // update filter paramter in URL
  useEffect(() => {
    const queryParams = createQueryParams(searchObject);
    navigate({ search: `?${queryParams}` });
  }, [searchObject]);

  //reset search if no vehicle type is selected
  if (Object.keys(searchObject).length > 2 && !("ty" in searchObject)) {
    setSearchObject(defaultSearch);
  }

  const commonSearchParameters: ISearchParametersProps = {
    searchObject: searchObject,
    setSearchObject: setSearchObject,
    vehicleObject: { count: data.searchResult?.count, cars: data.searchResult?.cars }
  };

  return (
    <>
      <style>
        {`
          #mpi-carzilla-script-container .App {
            max-width: ${width};
          }
        `}
      </style>
      <DataArrayContext.Provider value={data as any}>
        <Suspense fallback={<CircularProgress />}>
          <Routes>
            <Route path={customerConfig.quickSearch} element={<QuickSearch {...commonSearchParameters} />} />
            <Route path={`${customerConfig.vehicleDetail}/:vehicleTy/:vehicleId/:vehicleH?/:vehicleM?/:vehicleNa?/:vehicleEn?/:vehicleTr?`} element={<VehicleDetail searchParameters={{ ...commonSearchParameters }} />} />
            <Route path={customerConfig.vehicleList} element={<VehicleList {...commonSearchParameters} />} />
            <Route path={customerConfig.detailSearch} element={<DetailSearch {...commonSearchParameters} />} />
            <Route path={customerConfig.vehicleSlider} element={<VehicleSlider {...commonSearchParameters} width={width} />} />
            <Route path={"admin/config"} element={<Configurator customerConfig={customerConfig} />} />
            <Route path={"admin/login"} element={<Login />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Suspense>
        <ToastContainer />
      </DataArrayContext.Provider>
    </>
  );
};

export default MainContainer;
