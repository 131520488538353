import { ISelectData } from "@/interfaces/ISelectData";
import { isEmpty } from "@/components/OwnLibrary";
import { ICheckboxProps } from "@/interfaces/ICheckboxProps";
import { SelectChangeEvent } from "@mui/material";
import { FILTER_VEHICLE_MANUFACTURER, FILTER_VEHICLE_MODEL } from "@/constants";
import { ISearchParametersProps } from "@/interfaces/ISearchParametersProps";

export function convertHexToRGBA(hex: string, alpha = 1) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export function checkUndefined(e: any): e is undefined {
  return e === undefined || e === "undefined";
}

export function checkNull(e: any): e is null {
  return e === null || e === "null";
}

export function checkUndefinedOrNull(e: any): e is undefined | null {
  return checkUndefined(e) || checkNull(e);
}

type Empty = [] | Record<string, never> | "" | '""';

export function checkEmpty(e: any): e is Empty {
  if (Array.isArray(e) && e.length < 1) return true;
  else if (!Array.isArray(e) && typeof e === "object" && Object.keys(e).length < 1) return true;
  else if (!Array.isArray(e) && typeof e !== "object" && (e === "" || e === '""')) return true;
  return false;
}

export function checkUndefinedNullOrEmpty(e: any): e is undefined | null | Empty {
  return checkUndefined(e) || checkNull(e) || checkEmpty(e);
}

export function getGridSize1280(width: number, size: { xl: any; lg: any; md: any; sm: any; xs: any }) {
  if (width > 1280) return size.xl;
  if (width > 960) return size.lg;
  if (width > 600) return size.md;
  if (width > 0) return size.sm;
  return size.xs;
}

export function getGridSize1920(width: number, size: { xl: any; lg: any; md: any; sm: any; xs: any }) {
  if (width > 1700) return size.xl;
  if (width > 1360) return size.lg;
  if (width > 1020) return size.md;
  if (width > 680) return size.sm;
  return size.xs;
}
// Funktion zum Löschen von Eigenschaften mit Wert "alle"
export const removePropertiesWithValueAlle = (obj: any) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === "alle") {
      delete obj[key];
    }
  });
};

export const handleChangeSelectBoxValue = (value: SelectChangeEvent<number>, parameterName: string, searchParameters: ISearchParametersProps, navigate: any) => {
  // wenn neue Hersteller wird gewählt, setzte EZ (irF, irT) zurück
  if (parameterName === FILTER_VEHICLE_MANUFACTURER) {
    const updatedSearchObject = {
      ...searchParameters.searchObject
    };

    // EZ zurücksetzen, unabhängig vom ausgewählten Wert
    delete updatedSearchObject.irF;
    delete updatedSearchObject.irT;

    // Modell zurücksetzen, wenn der Hersteller auf "alle" gesetzt wird
    if (value.toString() === "alle") {
      delete updatedSearchObject[FILTER_VEHICLE_MODEL];
    }

    // Aktualisierte Suchparameter setzen
    searchParameters.setSearchObject(updatedSearchObject);
  }

  // behandeln von `undefined` und leeren Werten
  if (value === undefined || value.toString() === "") {
    searchParameters.setSearchObject((prevSelectData: ISelectData) => {
      const tempSearchObject = { ...prevSelectData };
      delete tempSearchObject[parameterName];

      // Überprüfen ob das resultierende Objekt dem Typ ISelectData entspricht
      if (isEmpty(tempSearchObject)) {
        navigate(window.location.pathname);

        return {} as ISelectData; // Setzen Sie das Objekt als leeren ISelectData Typ zurück
      }

      return tempSearchObject;
    });
  } else {
    searchParameters.setSearchObject((prevSelectData: ISelectData) => {
      const updatedData = { ...prevSelectData };

      if (Object.prototype.hasOwnProperty.call(updatedData, parameterName)) {
        updatedData[parameterName] = value;
      } else {
        updatedData[parameterName] = value;
      }

      return updatedData;
    });
  }
};

export const handleChangeCheckBoxValue = (event: React.ChangeEvent<HTMLInputElement>, label: string, data: Record<string, number[]> | Record<string, string[]>, setSearchObject: React.Dispatch<React.SetStateAction<ISelectData>>) => {
  const { name, checked } = event.target;
  const values = data[label];

  // Zustandsaktualisierungsfunktion
  setSearchObject((prevCheckboxes) => {
    // Kopie des vorherigen Zustands erstellen
    const updatedData = { ...prevCheckboxes };

    if (checked) {
      // Spezialfall für "st" mit Wert 2
      if (name === "st" && values[0] === 2) {
        if (updatedData[name]) {
          updatedData[name].push(2, 4, 6);
        } else {
          updatedData[name] = [2, 4, 6];
        }
      }
      // Spezialfall für "vat" mit Wert "1"
      else if (name === "vat" && values[0] === "1") {
        updatedData[name] = [values[0]];
      }
      // Allgemeiner Fall: Wert hinzufügen
      else {
        updatedData[name] = prevCheckboxes[name] ? [...prevCheckboxes[name], values[0]] : [values[0]];
      }
    } else {
      // Checkbox wurde deaktiviert
      // Spezialfall für "st" mit Wert 2
      if (name === "st" && values[0] === 2) {
        updatedData[name] = updatedData[name].filter((value) => ![2, 4, 6].includes(value));
      }
      // Wert entfernen, wenn vorhanden
      else if (prevCheckboxes[name] && prevCheckboxes[name].length > 0) {
        updatedData[name] = prevCheckboxes[name].filter((value: any) => value !== values[0]);
      }

      // Entfernen der Eigenschaft, wenn sie leer ist oder ein leeres Array enthält
      if (!updatedData[name] || updatedData[name].length === 0) {
        delete updatedData[name];
      }
    }

    return updatedData;
  });
};

export const setValue = (label: string, name: string, data: Record<string, number[]> | Record<string, string[]>, searchObject: ISelectData) => {
  if (searchObject && searchObject[name] && Array.isArray(searchObject[name])) {
    const values = data[label];
    return searchObject[name]?.includes(values[0]);
  }
  return false;
};

export const convertDataToCheckBoxArray = (data: Record<string, any>, FILTER: string, showall?: boolean): ICheckboxProps[] => {
  const CheckBoxDataArray: ICheckboxProps[] = [];

  Object.entries(data || {}).forEach(([key, value]) => {
    if (showall || value[1] > 0) {
      const temp = {
        label: key,
        name: FILTER,
        checked: false,
        data: { [key]: value },
        color: value[1] > 0 ? "black" : "grey"
      };
      CheckBoxDataArray.push(temp);
    }
  });

  return CheckBoxDataArray;
};

export const getBackgroundColor = (value: number) => {
  switch (value) {
    case 0:
      return "red";
    case 1:
      return "yellow";
    case 2:
      return "green";
    default:
      return "transparent";
  }
};

//TODO: will be used when prefix data works
export const formatPhoneNumber = (number: string) => {
  return number;
};

//input: any string
//output: A number in format XXXXXXX
export const shortingString = (str: string, len = 40): string => {
  if (typeof str === "boolean") {
    return str ? "Ja" : "Nein";
  }
  if (str.length > len) {
    return str.slice(0, len) + "...";
  }
  return str;
};

//input: any number or string
//output: A number in format XXXXXXX
export const cleanNumberOrString = (value: number | string, decimalPlaces = 0): number => {
  let numericValue: number;
  if (typeof value === "string") {
    const cleanedValue = value.replace(/[^0-9.,-]/g, "");
    numericValue = parseFloat(cleanedValue.replace(",", "."));
    if (isNaN(numericValue)) {
      throw new Error("Invalid numeric value provided.");
    }
  } else {
    numericValue = value;
  }
  return numericValue;
};

//input: any number or string as brutto price. even with special characters.
//output: A netto price in format XXX.XXX,00 €
export const calculateNetPrice = (value: number | string, decimalPlaces = 0, vatRate = 19): string => {
  const numericValue = cleanNumberOrString(value);
  const netPrice = numericValue / (1 + vatRate / 100);
  return (
    new Intl.NumberFormat("de-DE", {
      style: "decimal",
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces
    }).format(netPrice) + " €"
  );
};

//input: any number or string. even with special characters.
//output: A string in format XXX.XXX,00
export const formatNumber = (value: number | string, decimalPlaces = 0): string => {
  let numericValue: number;

  if (typeof value === "string") {
    const cleanedValue = value.replace(/[^\d.,-]/g, "");
    numericValue = parseFloat(cleanedValue.replace(".", "").replace(",", ".").replace(".", ""));
    if (isNaN(numericValue)) {
      throw new Error("Invalid numeric value provided.");
    }
  } else {
    numericValue = value;
  }

  return new Intl.NumberFormat("de-DE", {
    style: "decimal",
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces
  }).format(numericValue);
};

//input: any number or string as price. even with special characters.
//output: A price in format XXX.XXX,00 €
export const formatPrice = (value: number | string, decimalPlaces = 0): string => {
  return formatNumber(value, decimalPlaces) + " €";
};

//subtext for netto prices
export const getNettoPriceString = (value: number | string, decimalPlaces = 0, isVat: boolean, vat: number): string => {
  const nettoPrice = calculateNetPrice(value, decimalPlaces, vat);
  return isVat ? `${nettoPrice} (Netto), ${vat}% MwSt.` : "MwSt. nicht ausweisbar";
};

//create a query string by params
export const createQueryParams = (params: Record<string, any>) => {
  const queryParams = new URLSearchParams();
  Object.keys(params).forEach((key) => {
    if (Array.isArray(params[key])) {
      queryParams.append(key, params[key].join(","));
    } else {
      queryParams.append(key, params[key]);
    }
  });
  return queryParams.toString();
};

//parse query string back to an object
export const parseQueryParams = (query: string): ISelectData => {
  const params = new URLSearchParams(query);
  const result: ISelectData = {};

  params.forEach((value, key) => {
    if (value.includes(",")) {
      result[key] = value.split(",").map((v) => (isNaN(Number(v)) ? v : Number(v)));
    } else {
      result[key] = isNaN(Number(value)) ? value : Number(value);

      if (!key.endsWith("F") && !key.endsWith("T")) {
        result[key] = [result[key]];
      }
    }
  });

  return result;
};
