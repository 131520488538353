import { Translations } from "./translations";

const enUS: Translations = {
  UserProfile: "User Profile",
  Vorlauf: "Leader",
  Bestand: "Duration",
  Verkauft: "Sold",
  Speichern: "Save",
  Verkaufsdatum: "Date of Sale",
  Verfügbarkeitsdatum: "Availability Date",
  AvailableDialogText: "Please enter the availability date.",
  SellDialogText: "Please enter the date of sale.",
  RetrieveDialogText: "The bike is planned but not yet available.",
  language: "Language",
  Filteroptionen: "Filter Options",
  FilterSchließen: "Close Filter",
  CreateNewBike: "Create New Bike",
  ColumnDefinition: "Column Definition",
  EditBranch: "Edit Branch",
  BranchId: "Branch Id",
  BranchName: "Branch Name",
  Street: "Street",
  PLZ: "Post Code",
  Location: "Location",
  AliasName: "AliasName",
  Phone: "Phone",
  Fax: "Fax",
  Basisdaten: "Basic Data",
  Öffnungszeiten: "Opening Hours",
  Day: "Day",
  From: "From",
  To: "To",
  ManufacturerColor: "Manufacturer Color Name",
  DealerPriceGross: "Dealer Price Gross",
  EditMultipleBikes: "Edit Multiple Bikes",
  EditInternalNumber: "Edit Internal Number",
  EditModelDescription: "Edit Model Description",
  EditBatteryCapacity: "Edit Battery Capacity",
  EditManufacturerColor: "Edit Manufacturer Color",
  EditWeight: "Edit Weight",
  EditNumberOfGears: "Edit Number of Gears",
  EditFrameNumber: "Edit Frame Number",
  EditFrameHeight: "Edit Frame Height",
  EditPriceGross: "Edit Gross Price",
  EditEan: "Edit Ean",
  AssignedColumns: "Columns Assigned to the table",
  AvailableColumns: "Available Columns",
  TableName: "Table Name",
  SetTableName: "Please Give a Table Name",
  ArrangeColumnsForward: "Arrange Columns Further Forward",
  ArrangeColumnsBackward: "Arrange Columns Further Backward",
  DiscardChanges: "Discard Changes ?",
  showLess: "Show less",
  showMore: "Show more",

  common: {
    and: "and",
    brutto: "gross",
    netto: "net",
    notAvailable: "Not available",
    von: "from",
    bis: "to"
  },
  Buttons: {
    Close: "Close",
    Cancel: "Cancel",
    Store: "Save",
    End: "End",
    Yes: "Yes",
    No: "No",
    DeleteForm: "Delete Form",
    CreateBike: "Create Bike",
    SaveBike: "Save Bike",
    BikeVerfügbar: "Bike is Available",
    BikeVerkaufen: "Sell Bike",
    VerkaufFehler: "Sale failed, post back to inventory",
    NichtVerfügbar: "Error, not yet available",
    FilterZurücksetzen: "Reset Filter",
    ExportCsv: "Export as CSV",
    ViewEditBike: "View/edit Bike",
    CopyBike: "Copy Bike",
    SellBike: "Sell ​​Bike",
    SaveSorting: "Save Sorting",
    SetupTable: "Setup Table"
  },
  tabs: {
    t0: "Overview",
    t1: "Data",
    t2: "Images",
    t3: "Export",
    t4: "State"
  },
  filters: {
    manufacturer: "manufacturer",
    model: "Model",
    category: "category",
    wheelSize: "Wheel Size",
    color: "Color",
    suitableFor: "Suitable For",
    frameMaterial: "Frame Material",
    frameShape: "Frame Shape",
    frameHeight: "Frame Height",
    batteryPosition: "Battery Position",
    batteryManufacturer: "Battery Manufacturer",
    batteryCapacity: "Battery Capacity",
    motorPosition: "Motor Position",
    gearType: "Gear Type",
    numberOfGears: "Number Of Gears",
    weight: "Weight",
    price: "Price",
    vehicleType: "Vehicle Type",
    branch: "Branch"
  },

  Bike: {
    ID: "ID",
    FilialId: "Branch Id",
    Hersteller: "Manufacturer",
    Kategorie: "Category",
    Preis: "Price",
    passendfür: "Suitable For",
    Farbe: "Color",
    Rahmenform: "FrameShape",
    RahmenMaterial: "Frame Material",
    BatterieHersteller: "Battery Manufacturer",
    BatteriePosition: "Battery Position",
    Batteriekapazität: "Baterry Capacity",
    Motorposition: "Motor Position",
    Gangschaltung: "Gear Type",
    Radgröße: "WheelSize",
    Getriebeart: "Gear Count",
    Modellbeschreibung: "ModelDescription",
    Rahmennummer: "Frame Number",
    Rahmenhöhe: "Frame Height (cm)",
    Gewicht: "Weight (kg)",
    AnzahlDerGänge: "Number of Gears",
    GeeignetFür: "Suitable For",
    Zustand: "Condition",
    HerstellerFarbname: "Manufacturer Color Name",
    LeasingVerfügbar: "Leasing Available",
    NichtRepSchaden: "Damage Unrepaired",
    Mwstsatz: "VAT Rate",
    MwstAusweisbar: "VAT Reportable",
    Nettopreis: "Net price",
    Bruttopreis: "Gross price",
    Bild: "Image",
    GTIN: "GTIN",
    Beschreibung: "Description",

    FrameMaterial: {
      ALUMINIUM: "Aluminium",
      CARBON: "Carbon",
      STEEL: "Steel",
      TITANIUM: "Titanium"
    },
    internalNumber: "Internal Number",
    BatteryManufacturer: {
      BOSCH: "Bosch",
      SHIMANO: "Shimano",
      PANASONIC: "Panasonic",
      YAMAHA: "Yamaha",
      Mahle: "Mahle",
      Fazua: "Fazua",
      OTHER_MANUFACTURER: "Other Manufacturer"
    },
    Manufacturer: {
      ADVANCED: "Advanced",
      BABBOES: "Babboes",
      BAKFIETS: "Bakfiets",
      BATAVUS: "Batavus",
      BENNOBIKES: "Benno Bikes",
      BERGAMONT: "Bergamont",
      BIRIA: "Biria",
      BMC_BIKES: "BMC Bikes",
      BMW: "BMW",
      BRENNABOR: "Brennabor",
      BULLIT: "Bullit",
      BUTCHERS_AND_BICYCLES: "Butchers & Bicycles",
      CA_GO: "Ca Go",
      CANNONDALE: "Cannondale",
      CANYON: "Canyon",
      CARLA_CARGO: "Carla Cargo",
      CARQON: "Carqon",
      CARVER: "Carver",
      CENTURION: "Centurion",
      CHIKE: "Chike",
      CONTOURA: "Contoura",
      CONWAY: "Conway",
      CUBE: "Cube",
      DIAMANT: "Diamant",
      DOLLY_BIKES: "Dolly Bikes",
      DYNABIKE: "Dynabike",
      ELECTRA: "Electra",
      EXCELSIOR: "Excelsior",
      FISCHER: "Fischer",
      FLYER: "Flyer",
      FOCUS: "Focus",
      GAZELLE: "Gazelle",
      GHOST: "Ghost",
      GRACE: "Grace",
      GUDEREIT: "Gudereit",
      HAIBIKE: "Haibike",
      HARTJE: "Hartje",
      HASE_BIKES: "Hase Bikes",
      HEAD_BIKE: "Head Bike",
      HERCULES: "Hercules",
      HINTERHER: "Hinterher",
      HNF: "HNF",
      HOHEACHT: "Hoheacht",
      HUSQVARNA: "Husqvarna",
      ISY: "ISY",
      JOHANSSON: "Johansson",
      KALKHOF: "Kalkhof",
      KAYZA: "Kayza",
      KETTLER: "Kettler",
      KOGA: "Koga",
      KREIDLER: "Kreidler",
      KTM: "KTM",
      LANDROVER_WL_BRAND: "Land Rover (WL Brand)",
      LAPIERRE: "Lapierre",
      LEKKER: "Lekker",
      LEMMO: "Lemmo",
      LETTLER: "Lettler",
      LOVENS: "Lovens",
      MERCEDES_BENZ: "Mercedes-Benz",
      MERIDA: "Merida",
      MOUSTACHE: "Moustache",
      NIHOLA: "Nihola",
      OMNIUM: "Omnium",
      ORBEA: "Orbea",
      PEGASUS: "Pegasus",
      PINARELLO: "Pinarello",
      PORSCHE: "Porsche",
      PROPAIN: "Propain",
      PROPHETE: "Prophete",
      QIO: "Qio",
      RABENEICK: "Rabeneick",
      RADON: "Radon",
      RALEIGH: "Raleigh",
      RAYMON: "Raymon",
      RIESE_UND_MUELLER: "Riese & Mueller",
      ROCKY_MOUNTAIN: "Rocky Mountain",
      ROSE: "Rose",
      ROTWILD: "Rotwild",
      SANTA_CRUZ: "Santa Cruz",
      SCHOOF_UND_JENSEN: "Schoof & Jensen",
      SCOTT: "Scott",
      SIMPLON: "Simplon",
      SPECIALIZED: "Specialized",
      STEVENS: "Stevens",
      STROMER: "Stromer",
      TERN: "Tern",
      TREK: "Trek",
      TRIOBIKE: "Triobike",
      TRIUMPH: "Triumph",
      UNION: "Union",
      UNIVEGA: "Univega",
      URBAN_ARROW: "Urban Arrow",
      URWAHN: "UrwaHN",
      VAN_RAAM: "Van Raam",
      VAUN: "Vaun",
      VELOE: "Veloe",
      VELORETTI: "Veloretti",
      VIKTORIA: "Viktoria",
      WALDBIKE: "Waldbike",
      WILIER: "Wilier",
      WINORA: "Winora",
      WINTHER_BIKES: "Winther Bikes",
      WOOM: "Woom",
      YOONIT: "Yoonit",
      GIANT: "Giant"
    },

    BatteryPosition: {
      INTEGRATED: "Integrated",
      FRAME: "Frame",
      RACK: "Rack"
    },

    MotorPosition: {
      MIDDLE_MOTOR: "Middle Motor",
      INTERNAL_GEAR: "Internal Gear Hub"
    },

    Category: {
      CargoBike: "Cargo Bike",
      FoldingBike: "Folding Bike",
      TrekkingBike: "Trekking Bike",
      MountainBike: "Mountain Bike",
      CityBike: "City Bike",
      CrossBike: "Cross Bike",
      RacingBike: "Racing Bike",
      FatBike: "Fat Bike",
      ChildrenBike: "ChildrenBike",
      OtherBike: "Other Bike"
    },
    Color: {
      BLACK: "Black",
      GREY: "Grey",
      BEIGE: "Beige",
      BROWN: "Brown",
      RED: "Red",
      GREEN: "Green",
      BLUE: "Blue",
      PURPLE: "Purple",
      GOLD: "Gold",
      WHITE: "White",
      ORANGE: "Orange",
      SILVER: "Silver",
      YELLOW: "Yellow"
    },

    Condition: {
      New: "New"
    },

    FrameShape: {
      LOW_STEP: "Low Step",
      TRAPEZE: "Trapeze",
      CROSSBAR: "Crossbar",
      FULLY: "Fully",
      MountainBike: "Mountain Bike",
      OTHER_SHAPE: "Other Shape"
    },
    GearType: {
      DERAILLEUR_GEARS: "Derailleur Gears",
      INTERNAL_GEARED_HUB: "Internal Geared Hub",
      CONTINUOUSLY_VARIABLE: "Continuously Variable",
      OTHER_GEAR_TYPE: "Other Gear Type"
    },

    WheelSize: {
      Size12: '12"',
      Size16: '16"',
      Size20: '20"',
      Size24: '24"',
      Size26: '26"',
      Size275: '27.5"',
      Size28: '28"',
      Size29: '29"',
      Size2026: '20/26"',
      Size20275: '20/27.5"',
      Size29275: '29/27.5"'
    },

    SuitableFor: {
      FEMALE: "Female",
      MALE: "Male",
      UNISEX: "Unisex",
      GIRLS: "Girls",
      BOYS: "Boys"
    },

    PriceType: {
      FIXED: "Fixed",
      ON_REQUEST: "On Request",
      LEASING_ONLY: "Leasing Only"
    },

    Currency: {
      EUR: "Euro"
    }
  },
  bike_internal_number: "Bike with internal number "
};

export default enUS;
