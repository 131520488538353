import { Translations } from "./translations";

const deDE: Translations = {
  Vorlauf: "Vorlauf",
  Bestand: "Bestand",
  Verkauft: "Verkauft",
  language: "Sprache",
  Speichern: "speichern",
  Verkaufsdatum: "Verkaufsdatum",
  Verfügbarkeitsdatum: "Verfügbarkeitsdatum",
  AvailableDialogText: "Bitte geben Sie das Verfügbarkeitsdatum ein.",
  SellDialogText: "Bitte geben Sie das Verkaufsdatum ein.",
  RetrieveDialogText: "Das Bike ist geplant aber noch nicht verfügbar.",
  UserProfile: "Benutzerprofil",
  Filteroptionen: "Filteroptionen",
  FilterSchließen: "Filter schließen",
  CreateNewBike: "neues Bike anlegen",
  ColumnDefinition: "Spaltendefinition",
  EditBranch: "Filiale Bearbeiten",
  BranchId: "Filial ID",
  BranchName: "Filial Name",
  Street: "Straße",
  PLZ: "PLZ",
  Location: "Standort",
  AliasName: "AliasName",
  Phone: "Telefon",
  Fax: "Fax",
  Basisdaten: "Basisdaten",
  Öffnungszeiten: "Öffnungszeiten",
  Day: "Tag",
  From: "Von",
  To: "Bis",
  ManufacturerColor: "Hersteller Farbname",
  DealerPriceGross: "Bruttopreis",
  EditMultipleBikes: "Mehrere Fahrräder bearbeiten",
  EditInternalNumber: "Auftragsnummer bearbeiten",
  EditModelDescription: "Modellbeschreibung bearbeiten",
  EditBatteryCapacity: "Batteriekapazität bearbeiten",
  EditManufacturerColor: "Hersteller-Farbname bearbeiten",
  EditNumberOfGears: "Anzahl der Gänge bearbeiten",
  EditWeight: "Gewicht bearbeiten",
  EditFrameNumber: "Rahmennummer bearbeiten",
  EditFrameHeight: "Rahmenhöhe bearbeiten",
  EditPriceGross: "Bruttopreis bearbeiten",
  EditEan: "EAN/TIN bearbeiten",
  AssignedColumns: "der Tabelle zugeordnete Spalten",
  AvailableColumns: "verfügbare Spalten",
  TableName: "Tabellenname",
  SetTableName: "Bitte geben Sie einen Tabellennamen ein",
  ArrangeColumnsForward: "Spalte weiter vorn anordnen",
  ArrangeColumnsBackward: "Spalte weiter hinten anordnen",
  DiscardChanges: "Änderungen verwerfen ?",
  showLess: "Weniger anzeigen",
  showMore: "Mehr anzeigen",

  common: {
    and: "und",
    brutto: "brutto",
    netto: "netto",
    notAvailable: "nicht verfügbar",
    von: "von",
    bis: "bis"
  },
  Buttons: {
    Close: "schließen",
    Cancel: "Abbrechen",
    Store: "speichern",
    End: "Ende",
    Yes: "Ja",
    No: "Nein",
    DeleteForm: "Formulardaten löschen",
    CreateBike: "anlegen",
    SaveBike: "speichern",
    BikeVerfügbar: "Bike ist verfügbar",
    BikeVerkaufen: "Bike verkaufen",
    VerkaufFehler: "Verkauf fehlgeschlagen, in Bestand zurückbuchen",
    NichtVerfügbar: "Irrtum, noch nicht verfügbar",
    FilterZurücksetzen: "Filter zurücksetzen",
    ExportCsv: "Als CSV exportieren",
    ViewEditBike: "Bike ansehen / bearbeiten",
    CopyBike: "Bike kopieren",
    SellBike: "Bike verkaufen",
    SaveSorting: "Sortierung speichern",
    SetupTable: "Tabellen-Setup"
  },
  tabs: {
    t0: "Übersicht",
    t1: "Daten",
    t2: "Bilder",
    t3: "Export",
    t4: "Status"
  },
  filters: {
    manufacturer: "Hersteller",
    model: "Modell",
    category: "Kategorie",
    wheelSize: "Radgröße",
    color: "Farbe",
    suitableFor: "passend für",
    frameMaterial: "Rahmen-Material",
    frameShape: "Rahmenform",
    frameHeight: "Rahmenhöhe",
    batteryPosition: "Batterie-Position",
    batteryManufacturer: "Batterie-Hersteller",
    batteryCapacity: "Batterie-Kapazität",
    motorPosition: "Motor-Position",
    gearType: "Gangschaltung",
    numberOfGears: "Anzahl der Gänge",
    weight: "Gewicht",
    price: "Preis",
    vehicleType: "Fahrzeugtyp",
    branch: "Filiale"
  },

  Bike: {
    ID: "ID",
    FilialId: "Filial-ID",
    Hersteller: "Hersteller",
    Kategorie: "Kategorie",
    Preis: "Preis",
    passendfür: "passend für",
    Farbe: "Farbe",
    Rahmenform: "Rahmenform",
    RahmenMaterial: "Rahmen-Material",
    BatterieHersteller: "Batterie-Hersteller",
    BatteriePosition: "Batterie-Position",
    Batteriekapazität: "Batterie-Kapazität",
    Motorposition: "Motorposition",
    Gangschaltung: "Gangschaltung",
    Radgröße: "Radgröße",
    Getriebeart: "Getriebeart",
    Modellbeschreibung: "Modellbeschreibung",
    Rahmennummer: "Rahmennummer",
    Rahmenhöhe: "Rahmenhöhe (cm)",
    Gewicht: "Gewicht (kg)",
    AnzahlDerGänge: "Anzahl der Gänge",
    GeeignetFür: "Geeignet für",
    Zustand: "Zustand",
    HerstellerFarbname: "Herstellerfarbname",
    LeasingVerfügbar: "Leasing verfügbar",
    NichtRepSchaden: "nicht rep. Schaden",
    Mwstsatz: "Mwstsatz",
    MwstAusweisbar: "Mwst ausweisbar",
    Nettopreis: "Nettopreis",
    Bruttopreis: "Bruttopreis",
    Bild: "Bild",
    GTIN: "GTIN",
    Beschreibung: "Beschreibung",

    FrameMaterial: {
      ALUMINIUM: "Aluminium",
      CARBON: "Carbon",
      STEEL: "Stahl",
      TITANIUM: "Titan"
    },
    internalNumber: "Auftragsnummer",

    BatteryManufacturer: {
      BOSCH: "Bosch",
      SHIMANO: "Shimano",
      PANASONIC: "Panasonic",
      YAMAHA: "Yamaha",
      Mahle: "Mahle",
      Fazua: "Fazua",
      OTHER_MANUFACTURER: "anderer Hersteller"
    },
    Manufacturer: {
      ADVANCED: "Advanced",
      BABBOES: "Babboes",
      BAKFIETS: "Bakfiets",
      BATAVUS: "Batavus",
      BENNOBIKES: "Benno Bikes",
      BERGAMONT: "Bergamont",
      BIRIA: "Biria",
      BMC_BIKES: "BMC Bikes",
      BMW: "BMW",
      BRENNABOR: "Brennabor",
      BULLIT: "Bullit",
      BUTCHERS_AND_BICYCLES: "Butchers & Bicycles",
      CA_GO: "Ca Go",
      CANNONDALE: "Cannondale",
      CANYON: "Canyon",
      CARLA_CARGO: "Carla Cargo",
      CARQON: "Carqon",
      CARVER: "Carver",
      CENTURION: "Centurion",
      CHIKE: "Chike",
      CONTOURA: "Contoura",
      CONWAY: "Conway",
      CUBE: "Cube",
      DIAMANT: "Diamant",
      DOLLY_BIKES: "Dolly Bikes",
      DYNABIKE: "Dynabike",
      ELECTRA: "Electra",
      EXCELSIOR: "Excelsior",
      FISCHER: "Fischer",
      FLYER: "Flyer",
      FOCUS: "Focus",
      GAZELLE: "Gazelle",
      GHOST: "Ghost",
      GRACE: "Grace",
      GUDEREIT: "Gudereit",
      HAIBIKE: "Haibike",
      HARTJE: "Hartje",
      HASE_BIKES: "Hase Bikes",
      HEAD_BIKE: "Head Bike",
      HERCULES: "Hercules",
      HINTERHER: "Hinterher",
      HNF: "HNF",
      HOHEACHT: "Hoheacht",
      HUSQVARNA: "Husqvarna",
      ISY: "ISY",
      JOHANSSON: "Johansson",
      KALKHOF: "Kalkhof",
      KAYZA: "Kayza",
      KETTLER: "Kettler",
      KOGA: "Koga",
      KREIDLER: "Kreidler",
      KTM: "KTM",
      LANDROVER_WL_BRAND: "Land Rover (WL Brand)",
      LAPIERRE: "Lapierre",
      LEKKER: "Lekker",
      LEMMO: "Lemmo",
      LETTLER: "Lettler",
      LOVENS: "Lovens",
      MERCEDES_BENZ: "Mercedes-Benz",
      MERIDA: "Merida",
      MOUSTACHE: "Moustache",
      NIHOLA: "Nihola",
      OMNIUM: "Omnium",
      ORBEA: "Orbea",
      PEGASUS: "Pegasus",
      PINARELLO: "Pinarello",
      PORSCHE: "Porsche",
      PROPAIN: "Propain",
      PROPHETE: "Prophete",
      QIO: "Qio",
      RABENEICK: "Rabeneick",
      RADON: "Radon",
      RALEIGH: "Raleigh",
      RAYMON: "Raymon",
      RIESE_UND_MUELLER: "Riese & Mueller",
      ROCKY_MOUNTAIN: "Rocky Mountain",
      ROSE: "Rose",
      ROTWILD: "Rotwild",
      SANTA_CRUZ: "Santa Cruz",
      SCHOOF_UND_JENSEN: "Schoof & Jensen",
      SCOTT: "Scott",
      SIMPLON: "Simplon",
      SPECIALIZED: "Specialized",
      STEVENS: "Stevens",
      STROMER: "Stromer",
      TERN: "Tern",
      TREK: "Trek",
      TRIOBIKE: "Triobike",
      TRIUMPH: "Triumph",
      UNION: "Union",
      UNIVEGA: "Univega",
      URBAN_ARROW: "Urban Arrow",
      URWAHN: "UrwaHN",
      VAN_RAAM: "Van Raam",
      VAUN: "Vaun",
      VELOE: "Veloe",
      VELORETTI: "Veloretti",
      VIKTORIA: "Viktoria",
      WALDBIKE: "Waldbike",
      WILIER: "Wilier",
      WINORA: "Winora",
      WINTHER_BIKES: "Winther Bikes",
      WOOM: "Woom",
      YOONIT: "Yoonit",
      GIANT: "Giant"
    },
    BatteryPosition: {
      INTEGRATED: "integriert",
      FRAME: "Rahmen",
      RACK: "Gepäckträger"
    },

    MotorPosition: {
      MIDDLE_MOTOR: "Mittelmotor",
      INTERNAL_GEAR: "internes Getriebe"
    },
    Category: {
      CargoBike: "Lastenrad",
      FoldingBike: "Klapprad",
      TrekkingBike: "Trekkingrad",
      MountainBike: "Mountainbike",
      CityBike: "Stadtrad",
      CrossBike: "Crossrad",
      RacingBike: "Rennrad",
      FatBike: "Fatbike",
      ChildrenBike: "Kinder Fahrrad",
      OtherBike: "anderes Fahrrad"
    },

    Color: {
      BLACK: "Schwarz",
      GREY: "Grau",
      BEIGE: "Beige",
      BROWN: "Braun",
      RED: "Rot",
      GREEN: "Grün",
      BLUE: "Blau",
      PURPLE: "Lila",
      GOLD: "Gold",
      WHITE: "Weiss",
      ORANGE: "Orange",
      SILVER: "Silber",
      YELLOW: "Gelb"
    },

    Condition: {
      New: "Neu"
    },

    FrameShape: {
      LOW_STEP: "Tieftritt",
      TRAPEZE: "Trapez",
      CROSSBAR: "Diamant",
      FULLY: "Fully",
      MountainBike: "Mountain Bike",
      OTHER_SHAPE: "andere Form"
    },

    GearType: {
      DERAILLEUR_GEARS: "Kettenschaltung",
      INTERNAL_GEARED_HUB: "Nabenschaltung",
      CONTINUOUSLY_VARIABLE: "stufenlos variable Übersetzung",
      OTHER_GEAR_TYPE: "andere Getriebeart"
    },
    WheelSize: {
      Size12: '12"',
      Size16: '16"',
      Size20: '20"',
      Size24: '24"',
      Size26: '26"',
      Size275: '27.5"',
      Size28: '28"',
      Size29: '29"',
      Size2026: '20/26"',
      Size20275: '20/27.5"',
      Size29275: '29/27.5"'
    },

    SuitableFor: {
      FEMALE: "Frauen",
      MALE: "Männer",
      UNISEX: "Frauen & Männer",
      GIRLS: "Mädchen",
      BOYS: "Jungen"
    },

    PriceType: {
      FIXED: "Festpreis",
      ON_REQUEST: "Auf Anfrage",
      LEASING_ONLY: "Nur Leasing"
    },

    Currency: {
      EUR: "€"
    }
  },

  bike_internal_number: "Bike mit der internen Nummer"
};

export default deDE;
